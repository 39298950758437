@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap");
.dropdown {
  position: relative;
  /*   &:hover .dropdown-content {
      display: flex !important;
      flex-direction: column;
    } */
}
.dropdown-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.dropdown-button:hover {
  color: #00a76d;
}
.dropdown-content {
  left: 0;
  top: 100%;
  position: absolute;
  z-index: 100;
  background-color: white;
  border-radius: 8px;
  width: 300px;
  max-width: 300px;
  height: 100vh;
  display: flex !important;
  flex-direction: column;
}

.subPageContent {
  padding: 5px;
}

.full-screen-div-lock {
  z-index: 10;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.resizable-popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 40%;
  height: 90vh;
  max-width: 90%;
  min-width: 40%;
  max-height: 90vh;
  min-height: 40vh;
  resize: both;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
@media (max-width: 768px) {
  .resizable-popup {
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
  }
}
.resizable-popup .content {
  flex-grow: 1;
  min-height: 100%;
  height: 100px;
}
.resizable-popup .content2 {
  max-height: 90vh;
  min-height: 40vh;
}
.resizable-popup .controls {
  position: absolute;
  display: flex;
  min-width: 20%;
  justify-content: space-around;
  padding: 10px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
}

.global-list {
  color: #333;
  width: 100%;
  height: 100%;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.global-list .upper-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  background-color: #fafffd;
}
.global-list .fix-tags-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.global-list .fix-tags-container .fix-tags-title {
  margin-bottom: 10px;
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
.global-list .fix-tags-container .fix-tags-container {
  position: relative;
}
.global-list .fix-tags-container .close-fix-tags-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #d1d1d1;
  background-color: #f5f5f5;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  text-align: center;
  line-height: 1;
  padding: 0;
  transition: all 0.3s ease;
}
.global-list .fix-tags-container .close-fix-tags-btn:hover {
  background-color: salmon;
  border-color: #c1c1c1;
  color: #222;
}
.global-list .fix-tags-container .close-fix-tags-btn:active {
  background-color: #d1d1d1;
  border-color: #b1b1b1;
  color: #111;
}
.global-list .title {
  font-size: 24px;
  color: #333;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 600px) {
  .global-list .title {
    font-size: 20px;
  }
}

.tagsLabel {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.tagItem {
  background-color: #e8f0fe;
  border-radius: 15px;
  border-color: transparent;
  padding: 4px 6px;
  margin: 0px 2px;
  font-size: clamp(0.6rem, 2vh, 0.8rem);
  color: #1a73e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}
.tagItem:hover {
  background-color: #1a73e8;
  color: #ffffff;
  cursor: pointer;
}

body, html {
  margin: 0;
  background: grey;
  background-image: url("../../public/predor_karavanke.jpg");
  background-size: cover; /* Ensures the background covers the entire element */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat;
  font-family: "Montserrat", sans-serif;
  height: 100vh; /* Use 100vh for height */
  max-height: 100vh; /* 
overflow: auto; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.index-component-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column; /* This is crucial */
  overflow: hidden; /* Prevents overflow from this container */
  box-sizing: border-box;
  max-height: 100vh;
}
.index-component-wrapper-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  max-width: 300px;
  height: 100dvh;
  padding-top: 50px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-right: 5px solid rgba(255, 255, 255, 0.7);
  z-index: 500;
  background-color: #00a76d;
  box-shadow: 0 2px 4px rgb(0, 0, 0);
  transform: translateX(-300px);
  transition: transform 0.3s ease;
}
.index-component-wrapper-dropdown-menu-title {
  justify-content: center;
  padding: 5px;
}
.index-component-wrapper-dropdown-menu button {
  display: flex;
  padding: 0;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  background: none;
  cursor: pointer;
  color: white;
  font-size: clamp(14px, 2vw, 16px); /* Responsive font size using clamp */
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.2s ease;
  border-radius: 4px;
  border: none;
}
.index-component-wrapper-dropdown-menu button a {
  text-decoration: none;
  color: inherit;
  min-width: 100%;
  flex: 1;
  padding: 10px;
}
.index-component-wrapper-dropdown-menu button:hover {
  background-color: midnightblue;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.index-component-wrapper-log-in {
  position: absolute;
  left: 45%;
  top: 45;
}
.index-component-wrapper-navbar {
  user-select: none;
  color: white;
  justify-content: space-between;
  padding: 0.2rem 1rem;
  border-bottom: 1px solid white;
  background-color: #00a76d;
}
.index-component-wrapper-navbar-utility-menu {
  gap: 10px;
}
.index-component-wrapper-navbar-title {
  cursor: pointer;
}
.index-component-wrapper-navbar-title-logo {
  margin-right: 10px;
  vertical-align: bottom;
  margin-bottom: 0.1em;
  width: clamp(1rem, 2vw, 1.5rem);
  height: auto;
}
.index-component-wrapper-navbar-title-text, .index-component-wrapper-dropdown-menu-title {
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.index-component-wrapper-navbar-title-text:hover, .index-component-wrapper-dropdown-menu-title:hover {
  color: whitesmoke;
}
.index-component-wrapper-navbar-title-versionText {
  font-size: 1rem;
  font-weight: normal;
  align-items: center;
  cursor: pointer;
}
.index-component-wrapper-navbar-title-versionText:hover {
  color: whitesmoke;
}
.index-component-wrapper-main-content {
  flex-grow: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.openMenu {
  transform: translateX(0%);
}

*, *:before, *:after {
  box-sizing: border-box;
}

.ActionLogWrapper {
  box-sizing: border-box;
  position: absolute;
  top: 40px;
  right: 5px;
  z-index: 100;
  min-width: 250px;
  width: max-content;
  border-radius: 8px;
  min-height: 40px;
  border: 1px solid #d3d3d3;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  max-height: calc(98vh - 40px);
  resize: vertical;
  font-family: "Arial", sans-serif;
}
.ActionLogWrapper .head {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  background-color: #e8e8e8;
  border-bottom: 1px solid #d3d3d3;
}
.ActionLogWrapper .head .title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
}
.ActionLogWrapper .head .CopyToClip {
  font-size: 14px;
  color: #555;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
}
.ActionLogWrapper .head .CopyToClip:hover {
  color: #1a73e8;
}
.ActionLogWrapper ul {
  padding: 10px;
  font-size: 14px;
  overflow-y: auto;
  margin: 0;
  max-height: calc(98vh - 40px - 82px);
}
.ActionLogWrapper ul::-webkit-scrollbar {
  width: 6px;
}
.ActionLogWrapper ul::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 10px;
}
.ActionLogWrapper ul li {
  border-bottom: 1px solid #eee;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  margin: 5px 0px;
  transition: background-color 0.3s;
  cursor: help;
}
.ActionLogWrapper ul li:hover {
  background-color: #f0f0f0;
}
.ActionLogWrapper ul li .icon {
  text-align: center;
  padding-left: 3px;
}
.ActionLogWrapper ul li .message {
  padding-left: 5px;
  margin-right: 10px;
}
.ActionLogWrapper ul li .time {
  align-self: center;
  padding-right: 7px;
  color: #666;
}

.globals--module-navbar {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 100%;
  min-height: 25px;
  padding: 5px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .globals--module-navbar {
    padding: 0px 15px;
  }
}
.globals--module-navbar-title, .globals--module-navbar-subTitle {
  color: white;
  text-wrap: nowrap;
  font-weight: bold;
  font-size: clamp(0.6rem, 2vw, 1.2rem);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.globals--module-navbar-title-arrow {
  margin-left: 20px;
  transition: transform 0.3s ease;
}
@media (max-width: 768px) {
  .globals--module-navbar-title-arrow {
    margin-left: 5px;
  }
}
.globals--module-navbar-title:hover .globals--module-navbar-title-arrow, .globals--module-navbar-subTitle:hover .globals--module-navbar-title-arrow {
  transform: rotate(90deg);
}
.globals--module-navbar-title:hover .globals--module-navbar-dropdown-tree-menu, .globals--module-navbar-subTitle:hover .globals--module-navbar-dropdown-tree-menu {
  opacity: 1;
  transform: scaleY(1);
}
.globals--module-navbar-subTitle {
  text-wrap: nowrap;
  font-size: clamp(0.1rem, 2vw, 0.8rem);
  font-weight: normal;
}
.globals--module-navbar-dropdown-tree-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(0, 10, 20, 0.8);
  z-index: 100;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  height: 90vh;
}
@media (max-width: 768px) {
  .globals--module-navbar-dropdown-tree-menu {
    width: 75% !important;
  }
}

.globals--search-bar {
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 0px 0px 0px 12px;
}
.globals--search-bar-info-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: clamp(0.6rem, 2vh, 0.8rem);
  color: #5a5a5a;
  border-right: 1px solid #d3d3d3;
  padding: 0px 5px;
  text-wrap: nowrap;
}

input[type=text],
input[type=number],
input[type=date] {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #b1b1b1;
  color: #333;
  padding: 5px 0px;
}
input[type=text]:focus,
input[type=number]:focus,
input[type=date]:focus {
  outline: none;
  border-color: #00A24D;
  box-shadow: 0 0 5px rgba(0, 162, 77, 0.5);
}
input[type=text]::placeholder,
input[type=number]::placeholder,
input[type=date]::placeholder {
  color: #9a9a9a;
}

select {
  padding: 3px 30px 3px 10px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #d1d3d4;
  background-color: white;
  font-size: 1rem;
  color: #555;
  cursor: pointer;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23555" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}
select:focus {
  outline: none;
  border-color: #00A24D;
  box-shadow: 0 0 5px rgba(0, 162, 77, 0.5);
}

.globals--regular-button, .globals--red-button, .globals--green-button, .globals--transparent-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 12px;
  background-color: #ffffff;
  color: #4a4a4a;
  transition: filter 0.25s ease;
}
.globals--regular-button:hover, .globals--red-button:hover, .globals--green-button:hover, .globals--transparent-button:hover {
  filter: brightness(98%);
  transition: background-color 0.25s ease;
}
@media (max-width: 768px) {
  .globals--regular-button, .globals--red-button, .globals--green-button, .globals--transparent-button {
    padding: 2px 4px;
    font-size: clamp(0.6rem, 2vw, 1.2rem);
  }
}
.globals--regular-button i, .globals--red-button i, .globals--green-button i, .globals--transparent-button i {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}
.globals--regular-button:disabled, .globals--red-button:disabled, .globals--green-button:disabled, .globals--transparent-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.globals--green-button, .globals--transparent-button {
  background-color: #a4c3b2;
  color: white;
}
.globals--green-button:hover, .globals--transparent-button:hover {
  background-color: rgb(133.2649006623, 174.7350993377, 151.9933774834);
}

.globals--red-button {
  background-color: #c44d58; /* Grey shade */
  color: white;
}
.globals--red-button:hover {
  background-color: rgb(182.6404494382, 185.7865168539, 187.3595505618);
}

.globals--transparent-button {
  background-color: transparent;
  border: 1px solid transparent;
}
.globals--transparent-button:hover {
  background-color: midnightblue;
  color: #bcf124;
  border: 1px solid #bcf124;
}

.globals--close-button, .globals--chat-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #ff3b30;
  color: white;
  border: 1px solid #ccc;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  height: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}
.globals--close-button:hover, .globals--chat-button:hover {
  background-color: #cc2a1f;
  color: #1a1a1a;
}
.globals--close-button:before, .globals--chat-button:before {
  content: "×";
  display: block;
}

.globals--chat-button {
  position: sticky;
  color: blue;
  background-color: whitesmoke;
}
.globals--chat-button:before {
  content: none;
  display: block;
}
.globals--chat-button:hover {
  background-color: blue;
  color: whitesmoke;
}

.copyToClipButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #4a90e2;
  transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}
.copyToClipButton:hover {
  color: rgb(117.9571428571, 170.9571428571, 233.0428571429);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.copyToClipButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.5);
}
.copyToClipButton:active {
  color: rgb(34.3857142857, 117.3857142857, 214.6142857143);
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.helpers--section-container-orientation-flex-row, .index-component-wrapper-navbar, .index-component-wrapper-navbar-utility-menu, .index-component-wrapper-navbar-title {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .mobile-hide {
    display: none !important;
  }
}

.colors--white {
  color: white;
}

.form {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  max-height: 100vh;
  overflow: auto;
  font-size: clamp(0.6rem, 2vh, 0.8rem);
}
.form .form-header, .form .header {
  font-weight: 600;
  text-align: center;
  color: #2c3e50;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #2c3e50;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #636B59;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
}
.form .form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form .form-row label {
  width: 30%;
  text-align: right;
  margin-right: 10px;
}
.form .form-row input {
  flex-grow: 1;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.form .form-row input:focus {
  outline: none;
}
.form .section {
  display: grid;
  grid-template-columns: 3fr 4fr;
  padding: 5px 0;
  border-top: 1px solid #ddd;
}
.form .section .sectionName {
  font-weight: bold;
  color: #636B59;
}
.form .section .sectionName.additionalSectionColor {
  color: darkgreen;
  background-color: rgba(0, 0, 0, 0.1);
}
.form .section .dataSection {
  text-align: right;
}

table {
  border-collapse: collapse;
  background-color: white;
  text-align: left;
  width: 100%;
  max-width: 100vw;
}
table thead tr {
  background-color: #2c3e50;
}
table thead tr th {
  font-size: clamp(0.4rem, 2vh, 0.6rem);
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 5px;
}
table thead tr th:first-child {
  border-top-left-radius: 8px;
}
table thead tr th:last-child {
  border-top-right-radius: 8px;
}
table tbody tr {
  font-size: clamp(0.4rem, 2vh, 0.8rem);
}
table tbody tr:nth-child(even), table tbody tr:nth-child(odd) {
  background-color: #f4f7f6;
}
table tbody tr:hover {
  background-color: #e8eeed;
}
table tbody tr td {
  padding: 2px 20px;
  border-bottom: 1px solid #eee;
  color: #2c3e50;
}

/* $font-family: 'Arial', sans-serif; */
@keyframes expandDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    min-height: 55px;
    opacity: 1;
  }
}
.main-component-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: rgba(247, 248, 250, 0.8);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: relative;
}
.main-component-wrapper .tag-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  background-color: #f8f9fa;
  resize: vertical;
}
.main-component-wrapper .resizable-container {
  resize: vertical;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  animation: expandDown 0.8s ease forwards;
  border: none;
}
.main-component-wrapper-main-content {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
.main-component-wrapper-main-content-recursive-containerHelper {
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  max-width: 90vw; /* 
padding: 0px 20px 20px 20px; */
}
.main-component-wrapper-main-content-recursive-containerHelper .addNewButton {
  margin: 5px 0px;
  width: 100%;
  text-align: left;
  color: #ccc;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.5s ease, color 0.3s ease;
}
.main-component-wrapper-main-content-recursive-containerHelper .addNewButton:hover {
  background-color: rgba(0, 150, 0, 0.4);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.main-component-wrapper-main-content .descriptionBoxContainer {
  background-color: #ffffff;
  margin-left: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.02);
  overflow-y: auto; /* 
overflow-x: hidden; */
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  min-width: min-content;
  max-width: 260px;
}

.text-area-background-shade {
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.text-area-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 80%;
  max-height: 90vh;
  max-width: 30vw;
  min-height: 300px;
  min-width: 300px;
  overflow: auto;
  background-color: #00A24D;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.text-area-overlay.data-display {
  resize: both;
  max-width: 90%;
  min-width: 50%;
}
.text-area-overlay .button-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.text-area-overlay .button-section.data-display-buttons {
  flex-direction: row;
  justify-content: space-around;
}
.text-area-overlay .preview-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.text-area-overlay .excel-preview {
  width: 100%;
  max-height: 80%;
  overflow: auto;
  margin-bottom: 20px;
  padding-top: 20px;
  background-color: #f2f2f2;
  color: black;
  border-radius: 5px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.descrBox-wrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 12px;
  width: 250px;
  margin: 5px;
  border-radius: 8px;
  font-size: clamp(0.6rem, 2vh, 0.8rem);
}
.descrBox-wrapper .DBhead {
  font-weight: 600;
  color: #333;
  text-align: center;
  font-style: normal;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid transparent;
}
.descrBox-wrapper .DBhead .title {
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  color: #555;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.descrBox-wrapper .status {
  padding: 4px;
  border-top: 1px solid black;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  color: black;
}
.descrBox-wrapper .status .statusBar {
  border: 1px solid rgb(112, 107, 107);
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: white;
}
.descrBox-wrapper .status .statusBar.notForm {
  cursor: default;
  grid-column-start: 1;
  grid-column-end: 3;
}
.descrBox-wrapper .status .statusBar.isForm {
  width: 160px;
}
.descrBox-wrapper .status .statusNameMask {
  width: 206px;
}
.descrBox-wrapper .sectionTitle {
  padding: 8px 8px;
  border-top: 1px solid black;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.descrBox-wrapper .sectionTitle .alignRight {
  padding: 4px 0;
  color: #777;
  justify-content: flex-end;
}
.descrBox-wrapper .sectionTitle.flex {
  display: flex;
  justify-content: space-between;
}
.descrBox-wrapper .sectionTitle.noBorder {
  border-top: none !important;
}
.descrBox-wrapper .footer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid transparent;
  padding: 7px 0 2px;
}
.descrBox-wrapper .textInput {
  padding: 5px;
  color: rgb(55, 55, 55);
  font-size: 13px;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  word-break: break-all !important;
}
.descrBox-wrapper .textInput.sectionInput {
  width: 50px;
  text-align: center;
}
.descrBox-wrapper .fileName {
  font-family: "Times New Roman", Times, serif;
  padding: 0 5px 5px;
  font-weight: bold;
  color: rgb(55, 55, 55);
  font-size: 12px;
  word-break: break-all;
  user-select: text;
}
.descrBox-wrapper .trashCan {
  height: 35px;
  padding: 0px 7px 0px 8px;
  font-size: 25px;
  border: 1px solid transparent;
  border-radius: 30px;
  color: black;
  cursor: pointer;
  float: right;
}
.descrBox-wrapper .trashCan:active {
  border-color: darkred;
  color: darkred;
}

.OverviewWrapper {
  display: flex;
  flex-direction: row;
}
.OverviewWrapper .ItemList, .OverviewWrapper .changesList {
  width: 20%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow-y: auto;
  max-height: 100%;
}
.OverviewWrapper .title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.OverviewWrapper .title .nodeCount {
  font-size: 0.8em;
  color: #666;
  font-style: italic;
  margin-left: 10px;
  vertical-align: middle;
  text-align: end;
}
.OverviewWrapper .listComponent ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.OverviewWrapper .listComponent ul li {
  font-size: 0.95em;
  color: #555;
  padding: 8px 15px;
  border-bottom: 1px solid #f7f7f7;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  hyphens: auto;
}
.OverviewWrapper .listComponent ul li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.OverviewWrapper .listComponent ul li:last-child {
  border-bottom: none;
}
.OverviewWrapper .graphSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}
.OverviewWrapper .graphSection .pieChart {
  color: #555;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  height: 50%;
  width: auto;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: background-color 0.3s;
  font-family: Arial, sans-serif;
  font-size: 16px;
}
.OverviewWrapper .graphSection .pieChart .progressBar {
  display: flex;
  flex-direction: column;
  width: 5%;
  height: 100%;
  justify-content: flex-end;
  background-color: #f0f0f0; /* Light gray background */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  position: relative;
}
.OverviewWrapper .graphSection .pieChart .progressBar .progress {
  background: linear-gradient(to top, darkgreen, #69c16d); /* Gradient green */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  color: white;
  font-weight: bold;
  text-align: center;
  font-family: "Arial", sans-serif; /* Sleek, readable font */
  box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.2); /* Inner shadow for depth */
}
.OverviewWrapper .graphSection .barChart {
  color: #444;
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  height: 50%;
  width: auto;
  padding: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: background-color 0.3s;
  font-family: Georgia, serif;
  font-size: 18px;
}
.OverviewWrapper .graphSection .statusBar {
  background-color: green;
  height: 5%;
}
.OverviewWrapper .graphSection .title {
  color: #555;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  width: auto;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.3s;
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.history-log-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0 0 0;
  border-left: 3px solid #007bff;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.history-log-item .log-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.history-log-item .log-header .change-owner {
  font-weight: bold;
  color: #333;
  background-color: rgb(219, 219, 219);
  text-align: center;
  align-items: center;
  align-self: center;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-size: small;
}
.history-log-item .log-header .date {
  text-align: right;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  font-size: small;
  gap: 1rem;
}
.history-log-item .log-header .date .date-date {
  color: #666;
  display: block;
  margin-bottom: 5px;
}
.history-log-item .log-header .date .date-time {
  color: #999;
  display: block;
}
.history-log-item .change-description {
  color: #333;
  font-size: 0.95em;
  margin-bottom: 8px;
  line-height: 1.4;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: small;
}
.history-log-item .change-description h3 {
  color: blue;
  margin: 0;
}
.history-log-item .change-description h4 {
  color: red;
}
.history-log-item .change-description strong {
  color: #5c5c5c;
  margin-left: 10px;
}
.history-log-item .change-description li {
  width: 100%;
}
.history-log-item .change-description ul {
  margin: 0;
}
.history-log-item .change-description blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 1em 10px 0.1em;
  quotes: "“" "”" "‘" "’";
}
.history-log-item .status-change {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9em;
}
.history-log-item .status-change .new-status {
  font-weight: bold;
  color: #28a745;
}
.history-log-item .status-change .status-change-indicator {
  color: #dc3545;
}

.recursiveWrapper {
  user-select: none;
  box-sizing: border-box;
  width: 100%;
}
.recursiveWrapper .regularNode {
  display: flex;
  background-color: #fafafa;
  border: 1px solid rgba(220, 220, 220, 0.8);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  color: #444;
  box-sizing: border-box;
}
.recursiveWrapper .childNode {
  display: flex;
  box-sizing: border-box;
  background-color: #f2f2f2;
  border: 1px solid rgba(200, 200, 200, 0.8);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  color: black;
}
.recursiveWrapper .specialNode {
  display: flex;
  border: 1px solid rgba(0, 50, 0, 0.2);
  background-color: #eef0f1;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  padding: 0px 0px;
  padding-left: 15px;
  color: #333;
  box-sizing: border-box;
}
.recursiveWrapper .buttonWithWidth {
  max-width: 55px;
  min-width: 55px;
}
.recursiveWrapper .divWithWidth {
  max-width: 70px;
  min-width: 70px;
}
.recursiveWrapper .greenButton {
  color: green;
  cursor: pointer;
}
.recursiveWrapper:hover {
  background-color: white;
  transition: background-color 0.3s ease-in-out;
  border-radius: 6px;
}
.recursiveWrapper .rec-title {
  font-size: 11px;
  font-weight: 500;
}
.recursiveWrapper .quickInfoRight {
  display: flex;
  flex-direction: row;
  gap: 5px; /* 
grid-template-columns: 60px 20px min-content min-content 30px 50px 60px 30px 70px 30px 140px 140px 140px 140px; */
  align-items: center;
  text-align: center;
}
.recursiveWrapper .quickInfoRight.move-items {
  grid-template-columns: 100% 100% 100%;
}
.recursiveWrapper .quickInfoRight .fileExtension {
  color: darkred;
  font-size: 11px;
}
.recursiveWrapper .quickInfoRight .itemSection {
  font-size: 11px;
}
.recursiveWrapper .quickInfoRight .typeOfItem {
  font-size: 11px;
  color: brown;
}
.recursiveWrapper .quickInfoRight .scale {
  font-size: 11px;
  color: blue;
}
.recursiveWrapper .quickInfoRight .link {
  font-size: 11px;
  cursor: pointer;
  color: blue;
  white-space: nowrap;
  padding-right: 10px;
}
.recursiveWrapper .quickInfoRight .descriptionIcon {
  font-size: 14px;
  color: red;
  cursor: pointer;
  margin-right: 5px;
}
.recursiveWrapper .quickInfoRight .descriptionIcon .name {
  font-size: 14px;
}
.recursiveWrapper .quickInfoRight .description {
  overflow-wrap: break-word;
  min-height: 50px;
  width: 250px;
  resize: vertical;
}
.recursiveWrapper .quickInfoRight .dateCreated {
  font-size: 11px;
}
.recursiveWrapper .quickInfoRight .dateCreated .dateCreatedName {
  font-size: 11px;
  color: blue;
}
.recursiveWrapper .quickInfoRight .dateCreated .deadline {
  font-size: 11px;
  color: red;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton {
  display: flex;
  align-items: center;
  padding: 1px 10px;
  margin: 1px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer:last-child,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer:last-child,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton:last-child {
  margin-right: 0;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer span.icon,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer span.icon,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton span.icon {
  margin-right: 5px;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer input, .recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer button,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer input,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer button,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton input,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton button {
  margin-left: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 10px;
  outline: none;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer input:focus, .recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer button:focus,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer input:focus,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer button:focus,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton input:focus,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton button:focus {
  border-color: #007bff;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer button,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer button,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer button:hover,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer button:hover,
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton button:hover {
  background-color: rgb(0, 98.4, 204);
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer, .recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer {
  flex-direction: row;
  justify-content: flex-start;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer input[type=text], .recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .parentIdContainer input[type=number], .recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer input[type=text], .recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .priorityNumberContainer input[type=number] {
  max-width: 100px;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton {
  margin: 0px 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 100px;
}
.recursiveWrapper .quickInfoRight .moveItemsContainer .flexRow .copyToClipButton .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}
.recursiveWrapper .context {
  background-color: #ffffff;
  position: absolute;
  list-style-type: none;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 5px 0;
}
.recursiveWrapper .context li {
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}
.recursiveWrapper .context li:hover {
  background-color: #f5f5f5;
}
.recursiveWrapper .openDivider {
  color: red;
  border-color: red;
}
.recursiveWrapper .closedDivider, .recursiveWrapper .openDivider {
  cursor: pointer;
  padding: 2px 10px;
  color: #333;
  width: 50px;
  transition: color 0.2s, background-color 0.2s;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.recursiveWrapper .closedDivider:hover, .recursiveWrapper .openDivider:hover {
  color: darkred;
  background-color: #f9f9f9;
  border-color: darkred;
}
.recursiveWrapper .subitemsNumber {
  font-size: 11px;
  padding-left: 3px;
  vertical-align: top;
}
.recursiveWrapper .itemName {
  display: block;
  justify-content: space-between;
  padding-left: 10px;
  margin: 2px 0px;
  border-radius: 10px;
  font-size: 14px;
  background-color: transparent;
  width: 260px;
  cursor: help;
  white-space: normal;
  word-wrap: normal;
}
.recursiveWrapper .openingMovement {
  box-sizing: border-box;
  display: block;
  padding: 0 0 0 10px;
  border-left: 1px solid rgb(105, 105, 105);
}

.Monitoring-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  height: 100vh;
}
@media (max-width: 768px) {
  .Monitoring-wrapper {
    flex-direction: column;
  }
}
.Monitoring-wrapper .menu {
  background-color: #f7f8fa;
  flex: 0 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.Monitoring-wrapper .graph {
  background-color: rgb(247, 248, 250);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 auto; /* Take remaining space */
}
.Monitoring-wrapper .graph .graph-item {
  flex: 0 0 50%; /* This will work for two graphs */
  min-width: 250px;
}
.Monitoring-wrapper .graph .graph-item.full-width {
  flex: 0 0 100%; /* This will be used for a single graph */
  min-width: 500px;
}
@media (max-width: 768px) {
  .Monitoring-wrapper .graph .graph-item {
    flex: 0 0 100%; /* This will be used for a single graph */
  }
}
.Monitoring-wrapper .Sensor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding: 6px 0;
  color: #666;
  transition: color 0.2s, background-color 0.2s;
}
.Monitoring-wrapper .Sensor .Sensor-index {
  min-width: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #333;
}
.Monitoring-wrapper .Sensor .Sensor-info {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Monitoring-wrapper .Sensor .Sensor-label {
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
}
.Monitoring-wrapper .Sensor .Sensor-coordinates {
  font-size: 12px;
  color: #999;
}
.Monitoring-wrapper .Sensor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding: 6px 0;
  color: #666;
  transition: color 0.2s, background-color 0.2s;
}
.Monitoring-wrapper .Sensor:hover {
  color: #ff9900;
  background-color: #f5f5f5;
  cursor: pointer;
}
.Monitoring-wrapper .Sensor:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.Monitoring-wrapper .Sensor-label {
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
}
.Monitoring-wrapper .Sensor-coordinates {
  font-size: 12px;
  color: #999;
}
.Monitoring-wrapper .charts {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  padding: 5px;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 300px;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
}
@media (max-width: 768px) {
  .Monitoring-wrapper .charts {
    display: none;
  }
}
.Monitoring-wrapper .charts .legendTitle {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  padding-bottom: 5px;
  border-bottom: 3px solid #00a76d;
  color: #495057;
}
.Monitoring-wrapper .charts .legend-button {
  width: 3em;
  height: 2em;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #00a76d;
  padding: 3px;
  border-radius: 10px;
  margin: 2px;
}
.Monitoring-wrapper .charts .legend-button.initGraphViewButton {
  background-color: #0056b3;
}
.Monitoring-wrapper .charts .legend-button.initGraphViewButton:hover {
  background-color: #00a76d;
}
.Monitoring-wrapper .charts input[type=text] {
  max-width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  font-size: 16px;
  color: #495057;
}
.Monitoring-wrapper .charts input[type=text]:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.Monitoring-wrapper .charts button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.Monitoring-wrapper .charts button:hover {
  background-color: #0056b3;
}
.Monitoring-wrapper .charts .legendContent {
  max-height: 90vh;
  overflow-y: auto;
  padding: 10px;
}
.Monitoring-wrapper .charts .legendContent ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.Monitoring-wrapper .charts .legendContent .legend-item {
  display: flex;
  align-items: center;
}
.Monitoring-wrapper .charts .legendContent .legend-item .color-indicator {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
}
.Monitoring-wrapper .charts .legendContent .legend-item .color-indicator .color-box {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  padding-bottom: 22px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.Monitoring-wrapper .charts .legendContent .legend-item input[type=checkbox] {
  margin-right: 8px;
}

.insertNodeFormContainer {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.insertNodeFormContainer .insertNodeForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr;
  padding: 20px;
  width: 80%; /* Adjust the width as needed */
  /* height: 200px; */ /* Adjust the height as needed */
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid gray;
  border-radius: 10px;
}
.insertNodeFormContainer .insertNodeForm input[type=number] {
  width: 60px;
}
.insertNodeFormContainer .insertNodeForm .gridContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-column: span 2;
}
.insertNodeFormContainer .insertNodeForm .gridContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.insertNodeFormContainer .insertNodeForm .gridContainer > div > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.insertNodeFormContainer .insertNodeForm .gridContainer > div > div:not(:last-child) {
  margin-bottom: 5px;
  color: white;
}
.insertNodeFormContainer .insertNodeForm textarea {
  resize: none;
}
.insertNodeFormContainer .insertNodeForm button {
  width: 100%;
  cursor: pointer;
}

.geo-reports-component-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: rgba(247, 248, 250, 0.8);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: relative;
}
.geo-reports-component-wrapper-main-content {
  font-family: "IBM Plex Sans", sans-serif;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

.formContainer {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.02);
  overflow-y: auto;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  min-width: min-content;
  max-width: 260px;
  max-height: 100vh;
}

.userAccessControlPanel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: transparent;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: relative;
}
.userAccessControlPanel .content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  box-sizing: border-box;
  height: 100vh;
  overflow-x: hidden;
}
.userAccessControlPanel .content .tableContainer {
  background-color: #f4f7f6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: auto;
  transition: box-shadow 0.3s ease;
  width: 50vw;
}
.userAccessControlPanel .content .tableContainer:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.userAccessControlPanel .content .node-tree-section {
  padding: 20px;
  background-color: transparent;
  width: 50%;
  overflow: auto;
  box-sizing: border-box;
}

input {
  border-width: 1px;
}

.LOKACIJA-admin {
  grid-area: 1/1/2/5;
  position: relative;
  top: 10%;
  text-decoration: underline;
}

.LOKACIJA-email,
.LOKACIJA-password {
  grid-area: 2/1/3/5;
  position: relative;
  font-weight: 520;
}

.LOKACIJA-login {
  grid-area: 4/1/5/3;
  position: relative;
  top: 3px;
}

.LOKACIJA-puk {
  grid-area: 4/2/5/3;
  position: relative;
  left: 5px;
}

.LOKACIJA-showpsw,
.LOKACIJA-showpswlg {
  grid-area: 4/3/5/5;
  position: relative;
}

.LOKACIJA-resetpsw {
  grid-area: 5/1/6/6;
  position: relative;
  bottom: 10%;
}

.login {
  font-size: 20px;
}

.bold {
  font-weight: 700;
}

.tal {
  text-align: left;
}

.pr {
  position: relative;
}

.input {
  margin: 4px 0px 4px 10px;
  height: 26px; /* 
width: 225px; */
}
.input.input1 {
  width: 160px;
}
.input.input2 {
  width: 120px;
}
.input.input3 {
  width: 130px;
}
.input.input-login {
  width: 200px;
}
.input.input-narrow {
  width: 100px;
}
.input.input-very-narrow {
  width: 60px;
}
.input.input-table {
  width: 100%;
  margin: 0px;
  height: 26px;
}
.input.input-hm {
  width: 35px;
  margin-top: 2px;
  text-align: center;
}
.input.input-hm-narrow {
  width: 28px;
  margin-top: 2px;
  text-align: center;
}
.input.input-advance {
  margin-top: 2px;
  width: 85px;
}
.input.input-advance0 {
  margin-top: 2px;
  width: 130px;
}
.input.input-advance0.op0 {
  opacity: 0;
}
.input.input-file {
  z-index: 9999;
  margin: auto;
  inset: 0;
  opacity: 0;
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
}
.input.input-expense-name {
  margin-top: 2px;
  width: 135px;
}
.input.input-expense-value {
  margin-top: 2px;
  width: 75px;
}
.input.input-checkbox {
  margin-top: 2px;
  font-size: 12px;
  width: 15px;
}
.input.input-pass {
  width: 150px;
}
.input.pli {
  padding-left: 6px;
}

.pa {
  position: absolute;
}

.version {
  right: 10px;
  font-size: 11px;
  font-weight: 400;
}

.mt5 {
  margin-top: 2px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.tar {
  text-align: right;
}

.beforeinput {
  width: 61px;
  line-height: 32px;
}

.fl {
  float: left;
}

.dbl {
  display: block;
}

.button {
  color: #494949 !important;
  text-decoration: none;
  background: #fff;
  padding: 0px 10px;
  border: 2px solid #494949 !important;
  display: inline-block;
  transition: all 0.4s ease 0s;
}
.button.button-wide {
  padding: 0px 9px;
}

.button-thin {
  padding: 0px 5px;
  line-height: 22px;
  border: 1px solid #494949 !important;
}

.button-green {
  color: #20bf6b !important;
  border: 2px solid #20bf6b !important;
}
.button-green.button-thin {
  border: 1px solid #20bf6b !important;
}
.button-green:hover, .button-green.active {
  color: #fff !important;
  background: #20bf6b;
  border-color: #20bf6b !important;
  transition: all 0.4s ease 0s;
}
.button-green.button-nohover {
  color: #20bf6b !important;
  background: #fff;
  cursor: default !important;
}
.button-green:disabled, .button-green.inactive {
  color: #333 !important;
  background: #fff !important;
  border: 1px solid #494949 !important;
}
.button-green:disabled:hover, .button-green.inactive:hover {
  color: #333 !important;
  background: #fff !important;
  border: 1px solid #494949 !important;
}

.button-red {
  color: #ed3330 !important;
  border: 2px solid #ed3330 !important;
}
.button-red.button-thin {
  border: 1px solid #ed3330 !important;
}
.button-red:hover, .button-red.active {
  color: #fff !important;
  background: #ed3330;
  border-color: #ed3330 !important;
  transition: all 0.4s ease 0s;
}
.button-red.button-nohover {
  color: #ed3330 !important;
  background: #fff;
  cursor: default !important;
}
.button-red:disabled, .button-red.inactive {
  color: #333 !important;
  background: #fff !important;
  border: 1px solid #494949 !important;
}
.button-red:disabled:hover, .button-red.inactive:hover {
  color: #333 !important;
  background: #fff !important;
  border: 1px solid #494949 !important;
}

.mr {
  margin-right: 10px;
}

.red {
  color: #BE0000;
}

.password-problem {
  font-size: 14px;
  line-height: 1.1em;
}

.green {
  color: #009187;
}

.fr {
  float: right;
}

.ml5 {
  margin-left: 5px;
}

.checkbox {
  margin-top: 8px;
}

.reset-password {
  font-size: 14px;
}

.checkX {
  font-size: 20px;
}

.loginForm form {
  display: grid;
  min-width: 280px;
  min-height: 180px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  position: relative;
}
.loginForm .LOKACIJA-email {
  grid-area: 2/1/3/5;
  position: relative;
  font-weight: 520;
}
.loginForm .LOKACIJA-password {
  grid-area: 3/1/4/5;
  position: relative;
  font-weight: 520;
}
.loginForm .LOKACIJA-login {
  grid-area: 4/1/5/3;
  position: relative;
  top: 3px;
}
.loginForm .LOKACIJA-admin {
  grid-area: 1/1/2/5;
  position: relative;
  top: 10%;
  text-decoration: underline;
}
.loginForm .LOKACIJA-puk {
  grid-area: 4/2/5/3;
  position: relative;
  left: 5px;
}
.loginForm .LOKACIJA-showpsw {
  grid-area: 4/3/5/5;
  position: relative;
}
.loginForm .LOKACIJA-showpswlg {
  grid-area: 4/3/5/6;
  position: relative;
}
.loginForm .LOKACIJA-resetpsw {
  grid-area: 5/1/6/6;
  position: relative;
  bottom: 10%;
}

.app {
  background: #eee;
  height: 100%;
  text-align: center;
}
.app > svg.fs {
  height: 1000px;
  inset: 0 40px 100px 0;
  margin: auto;
  opacity: 0.5;
}
.app > svg.mb {
  width: 80%;
  inset: 0 0 440px 0px;
  margin: auto;
  opacity: 0.5;
}
.app > span.fs {
  width: 1000px;
  height: 100px;
  display: block;
  font-size: 60px;
  text-align: center;
  inset: 0 0 600px 0;
  margin: auto;
  opacity: 1;
  font-weight: 600;
}
.app > span.mb {
  width: 100%;
  height: 50px;
  display: block;
  font-size: 30px;
  text-align: center;
  inset: 0 0 250px 0;
  margin: auto;
  opacity: 1;
  font-weight: 600;
}

@media only screen and (max-width: 1023px) {
  .app > svg.fs,
  .app > span.fs {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .app > svg.mb,
  .app > span.mb {
    display: none;
  }
}
.App {
  border-radius: 10px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 10px;
  margin: auto;
  padding: 8px 20px;
  background: #FBFFFE;
  width: 320px;
  height: 198px;
  border: 1px solid #494949;
  position: fixed;
  text-align: center;
}

.reset-password-link {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  color: #333;
}
.reset-password-link:hover {
  color: #333;
}

.message-error {
  color: #BE0000;
  font-weight: 600;
}

.version {
  font-size: 12px;
  font-weight: 400;
}

.gray {
  color: #333;
  font-weight: 400;
}

.gray-700 {
  color: #333;
  font-weight: 700;
}

.hide {
  display: none;
}

.bold {
  font-weight: 700;
}

.virtualTourContainer {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  max-height: 100vh;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.virtualTourContainer .virtual-tour {
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;
}
.virtualTourContainer .virtual-tour iframe {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  border: none;
}

.ProfileWrapper {
  background-color: #f8f8f8;
  padding: 20px 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 320px;
  color: #333;
}
.ProfileWrapper .head {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  color: #005f73;
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 2px solid #d0e2e9;
}

.profileContent {
  display: flex;
  flex-direction: row;
}
.profileContent .tutorial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.profileContent .tutorial-heading {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}
.profileContent .tutorial-description {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 15px;
}
.profileContent .image-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.profileContent .tutorial-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .profileContent .tutorial-container {
    margin: 10px;
    padding: 15px;
  }
  .profileContent .tutorial-heading {
    font-size: 20px;
  }
  .profileContent .tutorial-description {
    font-size: 14px;
  }
}

.usersTable {
  overflow-y: auto;
  max-width: 100vw;
  height: 100vh;
}
.usersTable .usersPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: #f4f7f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #dce2e6;
}
.usersTable input {
  max-width: 150px;
}

.page-container {
  position: relative;
}

.page-button {
  cursor: pointer;
}

.page-content {
  position: absolute;
  top: 100%;
  z-index: 9999;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 300px;
  min-height: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  text-align: left;
}
.page-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.page-content ul li {
  display: flex;
  align-items: center;
  margin: 8px 0;
  line-height: 1.5;
}
.page-content ul li input[type=checkbox] {
  margin-right: 15px;
  cursor: pointer;
}
.page-content ul li input[type=checkbox]:disabled {
  opacity: 0.6;
}
.page-content ul li:hover {
  background-color: #f5f5f5;
}

.insertUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}
.insertUser .text-class {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
}
.insertUser .subheader-class {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #555;
  padding-bottom: 10px;
  border-bottom: 1px solid #2c3e50;
  margin-bottom: 10px;
}
.insertUser .logo-class {
  display: block;
  margin: 0 auto;
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 20px;
}
.insertUser .tutorial-class {
  color: #6c757d;
  font-size: 14px;
  border-left: 4px solid #007bff;
  padding-left: 10px;
  margin-bottom: 15px;
  background-color: #f8f9fa;
}

@media (max-width: 600px) {
  .iwantnewstyle form {
    width: 90%;
  }
  .iwantnewstyle form .form-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .iwantnewstyle form .form-row label, .iwantnewstyle form .form-row input {
    flex: none;
    margin: 5px 0;
  }
}
/* 

.page-container:hover .dropdown-content {
  display: block;
} */
.IFCviewer-container {
  /*width: 100%;*/
  /*height: 100vh;*/
  position: relative;
}
.IFCviewer-container-loading-screen-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 1000;
}
.IFCviewer-container-3D-viewer {
  /*width: 100%;*/
  /*height: 100%;*/
}

.IFCviewer-component-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100vh;
  /*flex-grow: 1; */
  /*overflow: hidden; */
  background-color: rgba(247, 248, 250, 0.8);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  position: relative;
}

.dashboard-main {
  background: linear-gradient(to bottom, orange, transparent);
  background-image: url("https://images.squarespace-cdn.com/content/v1/5dbac81daa74663c10257737/5e8b0006-69f9-46fc-989b-d0ca8635e9df/Artboard+1+copy+5.png?format=2500w");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-width: 100vw !important;
  overflow-x: hidden; /* 
overflow-y: hidden; */
  height: 100vh;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  color: orange;
  overflow: auto;
  height: 100%;
  max-width: 100vw !important;
  font-family: "Roboto Mono", monospace;
}

.project-card, .departure-form-content {
  background-color: rgba(51, 51, 51, 0.9);
  border-bottom: 1px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
}

.member-details {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  font-size: small;
}

.member-details strong {
  color: #fff;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.banner {
  width: 100%;
  white-space: nowrap;
  animation: slideIn 30s linear infinite;
  font-family: "Arial", sans-serif;
  font-size: 24px;
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 2px black;
  font-weight: bold;
}

.banner-container {
  background-color: rgba(51, 51, 51, 0.9);
  border-bottom: 1px solid white;
}

.full-width {
  width: 100%;
}

.no-resize {
  resize: none;
}

.rotated-span {
  display: inline-block;
  transform: rotate(-90deg);
  margin-right: 20px;
}

.banner-font {
  font-size: clamp(2rem, 2.5vw, 3rem);
}

.flex-grow {
  flex: 1;
}

.white-border {
  border-bottom: 1px solid white;
}

.flex-end {
  justify-content: flex-end;
}

.column-gap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.zero-margin {
  margin: 0;
}

.bold {
  font-weight: bold;
}

.white-text {
  color: white;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.text-end {
  text-align: end;
}

.no-wrap {
  text-wrap: nowrap;
}

.small-progress {
  height: 30%;
  font-size: clamp(1rem, 2vw, 1.5rem);
}

.row-max-width {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.large-font {
  font-size: clamp(1rem, 2vw, 4rem);
}

.flex-3 {
  flex: 3;
}

.inline {
  display: inline;
}

.margin-x {
  margin-right: 10px;
  margin-left: 10px;
}

.half-width {
  flex: 2;
}

.tall-progress {
  height: 90% !important;
}

.row-gap {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.space-between {
  justify-content: space-between;
}

.max-height {
  max-height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.border-top {
  border-top: 1px solid white;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-200%);
  }
}
.member-details .banner-section {
  overflow: hidden;
  margin: 0 10px;
  width: 100%;
}

.member-details .banner-section .moving-section {
  animation: slideIn 30s linear infinite;
  color: #FFD700;
  font-weight: bold;
}

.member-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.departure-form-container {
  height: 100%;
}
.departure-form-title {
  color: white;
}
.departure-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.departure-form-content {
  color: orange;
}
.departure-form-content-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.departure-form-content-body-status-container {
  min-width: 30%;
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: clamp(1rem, 2vw, 4rem);
}
.departure-form-content-body-users {
  cursor: pointer;
  text-align: end;
}
.departure-form-content-body-users:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.customScroll::-webkit-scrollbar, .main-component-wrapper-main-content-recursive-containerHelper::-webkit-scrollbar, .main-component-wrapper-main-content .descriptionBoxContainer::-webkit-scrollbar, .formContainer::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}
.customScroll::-webkit-scrollbar-thumb, .main-component-wrapper-main-content-recursive-containerHelper::-webkit-scrollbar-thumb, .main-component-wrapper-main-content .descriptionBoxContainer::-webkit-scrollbar-thumb, .formContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  transition: background-color 0.2s ease;
}
.customScroll::-webkit-scrollbar-thumb:hover, .main-component-wrapper-main-content-recursive-containerHelper::-webkit-scrollbar-thumb:hover, .main-component-wrapper-main-content .descriptionBoxContainer::-webkit-scrollbar-thumb:hover, .formContainer::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.customScroll::-webkit-scrollbar-corner, .main-component-wrapper-main-content-recursive-containerHelper::-webkit-scrollbar-corner, .main-component-wrapper-main-content .descriptionBoxContainer::-webkit-scrollbar-corner, .formContainer::-webkit-scrollbar-corner {
  background-color: transparent;
}

.add-new-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 6px;
  margin: 5px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  flex-direction: column;
}
.add-new-item.project {
  background-color: #007BFF;
}
.add-new-item.project:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
.add-new-item.project:active {
  background-color: #004085;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.add-new-item.project:focus {
  outline: none;
}
.add-new-item.workplace {
  background-color: #008248;
}
.add-new-item.workplace:hover {
  background-color: #026435;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
.add-new-item.workplace:active {
  background-color: #014021;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.add-new-item.workplace:focus {
  outline: none;
}
.add-new-item.sensor {
  background-color: #FFA500;
}
.add-new-item.sensor:hover {
  background-color: #FF8C00;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}
.add-new-item.sensor:active {
  background-color: #FF6F00;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.add-new-item.sensor:focus {
  outline: none;
}

.TreeItem {
  border-bottom: 1px solid #ccc;
  padding: 2px;
  margin-bottom: 2px;
  font-weight: normal;
}
.TreeItem-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #333;
  transition: color 0.2s, background-color 0.2s;
  background-color: #f5f5f5;
  padding: 2px 5px;
  font-size: 14px;
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
}
.TreeItem-label .edit {
  padding-left: 3px;
  transition: color 0.2s, background-color 0.2s;
}
.TreeItem-label .edit:hover {
  color: #4863da;
  background-color: #ffc4c4;
}
.TreeItem-label svg {
  width: 14px;
  height: 14px;
  margin-right: 6px;
  transition: transform 0.2s ease-in-out;
}
.TreeItem-label:hover {
  color: #1e9900;
  background-color: #e0e0e0;
}
.TreeItem-children {
  margin-left: 10px;
}

#map {
  width: 100%;
  height: 100%;
}

